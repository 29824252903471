import { searchContents } from "@/services/api";
import groupBy from "lodash.groupby";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useGetContentTypeUuid from "./useGetContentUuid";

export const useSearchContents = function ({
  keyword,
  contentTypeUuid,
  onSuccess,
} = {}) {
  const [results, setResults] = useState([]);
  const [startups, setStartups] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [total, setTotal] = useState(0);

  const search = async function (page = 1) {
    setLoading(true);

    try {
      const params = {
        page,
        keyword: keyword,
        content_type: contentTypeUuid,
        per_page: 10,
      };

      const response = await searchContents(params);

      const { current_page, data, last_page } = response.data;

      setError(false);
      setCurrentPage(current_page);
      setLastPage(last_page);
      setTotal(response.data.total);

      if (current_page == 1) {
        setResults(data.map((result) => result.searchable));
      } else {
        setResults((existing_result) => [
          ...existing_result,
          ...data.map((result) => result.searchable),
        ]);
      }

      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    search();
  }, [keyword, contentTypeUuid]);

  return {
    results,
    startups,
    investors,
    search,
    currentPage,
    lastPage,
    isLoading,
    isError,
    total,
  };
};

export const useSearchForm = function () {
  const router = useRouter();
  const [keyword, setKeyword] = useState(router.query.keyword || "");

  useEffect(() => {
    setKeyword(router.query.keyword);
  }, [router.query.keyword]);

  const doSearch = function (evt) {
    if (evt) {
      evt.preventDefault();
    }

    if (keyword) {
      router.push(`/search/results?keyword=${keyword}`);
    }
  };

  return { keyword, setKeyword, doSearch };
};
