import AppContext from "@/AppContext";
import { hidePurchasePopup, purchasePopup, userAtom } from "@/Atoms";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import useAuth from "@/hooks/auth";
import productsDev from "@/hooks/products.dev";
import productsProd from "@/hooks/products.prod";
import { createStripeCheckout } from "@/services/api/Stripe";
import { checkCoupon, createAbandonedCart } from "@/services/api/User";
import { createVCByUser } from "@/services/api/VentureCapital";
import { Check, Warning } from "@phosphor-icons/react";
import { loadStripe } from "@stripe/stripe-js";
import { useAtom } from "jotai";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

const products = process.env.ENV !== "production" ? productsDev : productsProd;

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
);

const theme = "light";

const VCCheckout = ({
  userLocation,
  currencyCode,
  priceData,
  priceIds,
  ip,
  isOpen,
  setIsOpen,
  createVC = false,
  createVCPayload,
}) => {
  const [openPurchasePopup, setOpenPurchasePopup] = useAtom(purchasePopup);
  const [isHidePurchasePopup, setIsHidePurchasePopup] =
    useAtom(hidePurchasePopup);

  const router = useRouter();
  const { type } = router.query;
  const planType =
    openPurchasePopup === "vc"
      ? "VC Registration"
      : openPurchasePopup === "startup"
      ? "Startup Registration"
      : "Content Access";
  const context = useContext(AppContext);
  const { planSubscription, loading, refreshUserSubscription } = context.state;
  const [playing, setIsPlaying] = useState(false);
  const [state, setState] = useState(null);
  const [userState, setUserState] = useAtom(userAtom);
  const [isCoupon, setIsCoupon] = useState(false);
  const [couponErrorProduct, setCouponErrorProduct] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [coupons, setCoupons] = useState(null);
  const [inputCoupon, setInputCoupon] = useState("");
  const [couponError, setCouponError] = useState(false);
  const [loadingCheck, setloadingCheck] = useState(false);
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: process.env.STRIPE_SECRET_KEY,
  };

  const handleCheckCoupon = async (e) => {
    e.preventDefault();
    if (!inputCoupon) return;
    if (loadingCheck || coupon) return;
    setCouponError(false);
    setCouponErrorProduct(false);
    setCoupon(null);
    setloadingCheck(true);
    
    try {
      const res = await checkCoupon(inputCoupon);
      
      if (res.data.redeem_by && Date.now() > res.data.redeem_by * 1000) {
        setCouponError(true);
        setloadingCheck(false);
        return;
      }

      if (res.data.applies_to && res.data.applies_to.products) {
        if (res.data.applies_to.products.includes(products[planType])) {
          setCoupon(res.data);
        } else {
          setCouponErrorProduct(true);
        }
      } else {
        setCoupon(res.data);
      }
      
      setloadingCheck(false);
    } catch (error) {
      console.log(error);
      setCouponError(true);
      setloadingCheck(false);
      setCoupon(null);
      setCouponErrorProduct(false);
    }
  };

  const handleSubscription = async (event) => {
    console.log(priceData);
    if (!priceData) {
      return;
    }
    if (!user) {
      router.push("/auth/login?redirect_to=/pricing");
      return;
    }
    setIsOpen(false);
    setOpen(true);
    // Get Stripe.js instance
    const stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    const response = await createStripeCheckout({
      userEmail: user.email,
      priceId: priceData["VC Registration"].id,
      type: "vc",
      coupon_id: coupon ? coupon.id : null,
      cancel_url: process.env.NEXT_PUBLIC_BASE_URL + router.asPath,
      // success_url: router.query?.redirect_to
      //   ? process.env.NEXT_PUBLIC_BASE_URL + router.query.redirect_to
      //   : null,
      success_url: process.env.NEXT_PUBLIC_BASE_URL + "vcregistration",
    });

    try {
      if (createVC && createVCPayload) {
        await createVCByUser(createVCPayload);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      }
      return;
    }

    const session = response.data;

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      alert(result.error.message);
    }
  };

  const onRemoveCoupon = () => {
    setInputCoupon("");
    setCoupon(null);
  };

  const onCancel = () => {
    setIsOpen(false);
    setInputCoupon("");
    setCoupon(null);
    setIsCoupon(false);
  };

  const currentPath = router.asPath;

  const handleCheckboxChange = () => {
    if (isCoupon) {
      setInputCoupon("");
      setCoupon(null);
    }
    setIsCoupon(!isCoupon);
  };

  const handleCouponChange = (e) => {
    setInputCoupon(e.target.value);
  };

  const handleAbandonedCart = async () => {
    const res = await createAbandonedCart({
      user_id: user?.id,
      email: user?.email,
      action: "Click VC Registration Trial",
      location: router.pathname,
      label: "VC Registration",
    });
  };

  useEffect(() => {
    handleAbandonedCart();
  }, []);

  return (
    <>
      <AlertDialog open={isOpen} onClose={() => setIsOpen(false)}>
        <AlertDialogContent className='overflow-x-auto text-gray-800 bg-white'>
          <AlertDialogHeader>
            <AlertDialogTitle className='mb-4'>
              Start Your 7-Day Free Trial of the VC Registration Plan
            </AlertDialogTitle>
            <div className='p-4 mt-2 rounded-lg bg-slate-100'>
              <div className='flex justify-between mb-2 font-semibold'>
                <p>Price</p>
                <p>
                  {currencyCode !== "USD" ? currencyCode + " " : "$"}
                  {(priceData
                    ? priceData["VC Registration"].unit_amount / 100
                    : 0
                  ).toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
              <div className='pt-5 pb-5'>
                <label className='flex items-center space-x-2 cursor-pointer'>
                  <Checkbox
                    onCheckedChange={handleCheckboxChange}
                    className={`${
                      theme === "light"
                        ? "border-background/50"
                        : "border-white/10"
                    }`}
                  />
                  <span className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
                    I have a coupon code
                  </span>
                </label>
                {isCoupon && (
                  <div className='flex items-center justify-between pt-5'>
                    <p className='text-sm font-medium'>
                      Coupon Code (Optional)
                    </p>
                    <div>
                      <div className='flex gap-2'>
                        <Input
                          onChange={handleCouponChange}
                          className='bg-white/5 hover:bg-white/10'
                          disabled={coupon}
                          value={inputCoupon}
                          onBlur={() => setCouponError(false)}
                        />
                        <Button
                          type='button'
                          onClick={coupon ? onRemoveCoupon : handleCheckCoupon}
                          className={`${
                            coupon
                              ? "bg-red-500/20 hover:bg-red-500/30 text-red-400"
                              : `${
                                  theme === "light"
                                    ? "bg-background/70 text-white hover:bg-background/50"
                                    : "bg-white text-background"
                                }`
                          }`}
                        >
                          {loadingCheck
                            ? "Checking..."
                            : coupon
                            ? "Remove"
                            : "Apply"}
                        </Button>
                      </div>
                      {coupon && (
                        <p className='flex items-center font-medium mt-1.5 text-xs text-green-500 gap-x-1.5'>
                          <Check weight='bold' /> Coupon Applied
                        </p>
                      )}
                      {couponErrorProduct && (
                        <p className='flex items-center font-medium mt-1.5 text-xs text-red-500 gap-x-1.5'>
                          <Warning weight='bold' /> This coupon is not valid for
                          this subscription.
                        </p>
                      )}
                      {couponError && (
                        <p className='flex items-center font-medium mt-1.5 text-xs text-red-500 gap-x-1.5'>
                          <Warning weight='bold' /> This Coupon does not exist
                          or has expired.
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {coupon && (
                <div className=''>
                  <div className='flex justify-between'>
                    <p>
                      Coupon <span className='text-sm'>({coupon.id})</span>
                    </p>
                    <p>
                      -{currencyCode !== "USD" ? currencyCode + " " : "$"}
                      {coupon.percent_off
                        ? Math.round(
                            (coupon.percent_off / 10000) *
                              priceData["VC Registration"].unit_amount
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : (coupon.amount_off / 100).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </p>
                  </div>
                  <div className='flex justify-between mt-2 font-bold'>
                    <p>Total</p>
                    <p>
                      {currencyCode !== "USD" ? currencyCode + " " : "$"}
                      {coupon.percent_off
                        ? (
                            priceData["VC Registration"].unit_amount / 100 -
                            Math.round(
                              (coupon.percent_off / 10000) *
                                priceData["VC Registration"].unit_amount
                            )
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : (
                            priceData["VC Registration"].unit_amount / 100 -
                            coupon.amount_off / 100
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </p>
                  </div>
                </div>
              )}
            </div>
            {/* <AlertDialogDescription>
          By clicking &quot;Proceed&quot;, you agree to
          proceed with the payment process and will be
          redirected to the secure payment page.
        </AlertDialogDescription> */}
          </AlertDialogHeader>
          <AlertDialogFooter className='flex sm:justify-between'>
            <AlertDialogCancel
              onClick={onCancel}
              className='bg-white hover:bg-slate-100'
            >
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleSubscription}
              className='text-white bg-gray-800 hover:bg-gray-500'
            >
              Go to Payment
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Loading Payment..</DialogTitle>
            <DialogDescription>
              <div className='mt-2'>
                Please wait...you will be redirected to the secure payment page.
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VCCheckout;
