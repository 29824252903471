import GoogleG from "@/assets/images/google-g-logo.svg";
import { fetchGoogleAuthDetail } from "@/services/api/User";
import Image from "next/legacy/image";
import { useEffect, useState } from "react";
import { setCookies } from "cookies-next";
import { useRouter } from "next/router";
import { Button } from "../ui/button";

function GoogleSignInButton({ label = "Sign In with Google" } = {}) {
  const [loginUrl, setLoginUrl] = useState(null);
  const router = useRouter();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetchGoogleAuthDetail();
        if (response.data.url) {
          setLoginUrl(response.data.url);
        }
      } catch (error) {
        console.log("Error", error);
      }
    }
    fetchData();
  }, []);

  const processGoogle = () => {
    // if (router.query.redirect_to) {
    //   setCookies("google-url-redirect-to", router.query.redirect_to);
    // }
    if (router.asPath) {
      setCookies("google-url-redirect-to", router.asPath);
    }
    const a = document.createElement("a");
    a.href = loginUrl;
    a.click();
  };

  if (!loginUrl) return null;
  return (
    <Button
      type='button'
      className='w-full border border-background/10 text-background/80'
      onClick={processGoogle}
    >
      <div className='relative h-3.5 mr-2 aspect-square'>
        <Image src={GoogleG} alt='Google' layout='fill' />
      </div>
      {label}
    </Button>
  );
}

export default GoogleSignInButton;
