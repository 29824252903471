function secondsToMinutes(seconds) {
    // Calculate the minutes part by dividing the seconds by 60 and taking the floor value
    const minutes = Math.floor(seconds / 60);
    // Calculate the remaining seconds part
    const remainingSeconds = seconds % 60;
    // Format the minutes and seconds to ensure two digits (e.g., "03" instead of "3")
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    // Combine the minutes and seconds in the mm:ss format
    return `${formattedMinutes}:${formattedSeconds}`;
}

export default secondsToMinutes;