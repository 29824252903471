// import { Dialog, DialogContent } from "@/components/ui/dialog";
import useAuth from "@/hooks/auth";
import { getStartupDetailSimple } from "@/services/api/Startup";
import checkEmailDomain from "@/utils/checkEmailDomain";
import { CircleNotch, X } from "@phosphor-icons/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import GoogleSignInButton from "../auth/GoogleSignInButton";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { Input } from "../ui/input";
import { Label } from "../ui/label";

import { useAtom } from "jotai";
import { registerPopup, subscribePopup } from "@/Atoms";
import { useReCaptcha } from "next-recaptcha-v3";
import { verifyCaptcha } from "@/services/api/Master";
import { subscribe } from "@/services/api/Newsletter";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Transition, Dialog } from "@headlessui/react";

const Subscribe = () => {
  const [openSubscribePopup, setOpenSubscribePopup] = useAtom(subscribePopup);
  const [openRegisterPopup, setOpenRegisterPopup] = useAtom(registerPopup);
  const [loadingSubscribe, setLoadingSubscribe] = useState(false);
  const { executeRecaptcha } = useReCaptcha();

  const handleSubscribe = useCallback(
    async (event) => {
      try {
        event.preventDefault();

        const email = event.target.email.value;
        const first_name = event.target.first_name.value;
        const last_name = event.target.last_name.value;
        const thisYear = new Date().getFullYear();

        setLoadingSubscribe(true);

        const token = await executeRecaptcha("form_subscribe");
        const response = await verifyCaptcha(token);

        if (response.data.score > 0.5) {
          const payload = {
            email,
            tags: ["Customer", String(thisYear), "Web"],
          };

          if (first_name) {
            payload.first_name = first_name;
          }

          if (last_name) {
            payload.last_name = last_name;
          }

          try {
            const res = await subscribe(payload);
            toast.success("You are successfully subscribed to our newsletter!");
            event.target.reset();
            setOpenSubscribePopup(false);
          } catch (error) {
            if (error.response.data.message == "User is already subscribed.") {
              toast.error(error.response.data.message);
            }
          }
          setLoadingSubscribe(false);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [executeRecaptcha]
  );

  function switchPopup() {
    setOpenSubscribePopup(false);
    setOpenRegisterPopup(true);
  }

  useEffect(() => {
    if (
      localStorage.getItem("lc_already_open_newsletter") === "true" &&
      !openRegisterPopup
    ) {
      localStorage.setItem("lc_already_open_newsletter", "false");
    }
  }, [openRegisterPopup]);

  return (
    <>
      {openSubscribePopup && (
        <div className='fixed z-50 px-5 py-4 overflow-hidden bg-white rounded-lg shadow-xl text-background bottom-0 inset-x-0 md:bottom-5 md:left-5 w-full md:w-[400px]'>
          <div className='relative'>
            <div className='absolute top-0 block -right-2'>
              <button
                type='button'
                className='text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                onClick={() => setOpenSubscribePopup(false)}
              >
                <span className='sr-only'>Close</span>
                <X className='w-5 h-5' aria-hidden='true' />
              </button>
            </div>
            <p className='text-xl font-semibold lg:text-2xl font-display'>
              Subscribe to Our Newsletter
            </p>
            <p className='mt-1.5 text-xs opacity-60'>
              to keep up to date with Lucidity Insights&apos; latest content
            </p>
            <form
              disabled={loadingSubscribe}
              onSubmit={handleSubscribe}
              className='grid grid-cols-12 gap-4 mt-5'
            >
              <div className='col-span-6 space-y-1 text-left'>
                <Label className='text-xs' htmlFor='first_name'>
                  First Name
                </Label>
                <Input
                  type='text'
                  id='first_name'
                  name='first_name'
                  required
                  className='h-auto border-gray-300 shadow-sm bg-white/5 hover:bg-white/10 focus-visible:ring-0'
                  disabled={loadingSubscribe}
                />
              </div>
              <div className='col-span-6 space-y-1 text-left'>
                <Label className='text-xs' htmlFor='last_name'>
                  Last Name
                </Label>
                <Input
                  type='text'
                  id='last_name'
                  name='last_name'
                  required
                  className='h-auto border-gray-300 shadow-sm bg-white/5 hover:bg-white/10 focus-visible:ring-0'
                  disabled={loadingSubscribe}
                />
              </div>
              <div className='col-span-12 space-y-1 text-left'>
                <Label className='text-xs' htmlFor='email'>
                  Email Address
                </Label>
                <Input
                  type='email'
                  id='email'
                  name='email'
                  required
                  className='h-auto border-gray-300 shadow-sm bg-white/5 hover:bg-white/10 focus-visible:ring-0'
                  disabled={loadingSubscribe}
                />
              </div>
              <div className='flex flex-col items-center col-span-12 gap-4'>
                <Button
                  type='submit'
                  variant='secondary'
                  className='w-full'
                  disabled={loadingSubscribe}
                >
                  {loadingSubscribe && (
                    <CircleNotch
                      weight='bold'
                      className='mr-2 text-lg animate-spin'
                    />
                  )}
                  {loadingSubscribe
                    ? "Processing..."
                    : "Subscribe to Our Weekly Newsletter"}
                </Button>
                {/* <div className='w-full'>
            <p className='mb-2 text-sm text-center opacity-50'>
              or if you want to have access to more of our content
            </p>
            <Button
              type='button'
              onClick={switchPopup}
              className='w-full bg-white border shadow-sm border-background/10'
            >
              Register Now
            </Button>
          </div> */}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
    //  <Dialog open={openSubscribePopup} onOpenChange={setOpenSubscribePopup}>
    //    <DialogContent className='p-8 pb-5 bg-white text-background'>
    //      <div className=''>
    //        <p className='text-3xl font-semibold tracking-tight lg:text-4xl font-display'>
    //          Subscribe to Our Newsletter
    //        </p>
    //        <p className='mt-2 opacity-70'>
    //          to keep up to date with Lucidity Insights&apos; latest content
    //        </p>
    //        <form
    //          disabled={loadingSubscribe}
    //          onSubmit={handleSubscribe}
    //          className='grid grid-cols-12 gap-4 mt-5'
    //        >
    //
    //            <Label className='text-sm' htmlFor='first_name'>
    //              First Name
    //            </Label>
    //            <Input
    //              type='text'
    //              id='first_name'
    //              name='first_name'
    //              required
    //              className='border-gray-300 shadow-sm bg-white/5 hover:bg-white/10 focus-visible:ring-0'
    //              disabled={loadingSubscribe}
    //            />
    //          </div>
    //          <div className='col-span-6 space-y-1.5 text-left'>
    //            <Label className='text-sm' htmlFor='last_name'>
    //              Last Name
    //            </Label>
    //            <Input
    //              type='text'
    //              id='last_name'
    //              name='last_name'
    //              required
    //              className='border-gray-300 shadow-sm bg-white/5 hover:bg-white/10 focus-visible:ring-0'
    //              disabled={loadingSubscribe}
    //            />
    //          </div>
    //          <div className='col-span-12 space-y-1.5 text-left'>
    //            <Label className='text-sm' htmlFor='email'>
    //              Email Address
    //            </Label>
    //            <Input
    //              type='email'
    //              id='email'
    //              name='email'
    //              required
    //              className='border-gray-300 shadow-sm bg-white/5 hover:bg-white/10 focus-visible:ring-0'
    //              disabled={loadingSubscribe}
    //            />
    //          </div>
    //          <div className='flex flex-col items-center col-span-12 gap-4'>
    //            <Button
    //              type='submit'
    //              variant='secondary'
    //              className='w-full'
    //              disabled={loadingSubscribe}
    //            >
    //              {loadingSubscribe && (
    //                <CircleNotch
    //                  weight='bold'
    //                  className='mr-2 text-lg animate-spin'
    //                />
    //              )}
    //              {loadingSubscribe
    //                ? "Processing..."
    //                : "Subscribe to Our Weekly Newsletter"}
    //            </Button>
    //            <div className='w-full'>
    //              <p className='mb-2 text-sm text-center opacity-50'>
    //                or if you want to have access to more of our content
    //              </p>
    //              <Button
    //                type='button'
    //                onClick={switchPopup}
    //                className='w-full bg-white border shadow-sm border-background/10'
    //              >
    //                Register Now
    //              </Button>
    //            </div>
    //          </div>
    //        </form>
    //      </div>
    //    </DialogContent>
    //  </Dialog>
  );
};

export default Subscribe;
