import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { CaretUpDown, Pause, Play, StopCircle } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import RelatedPodcast from "../podcast/RelatedPodcast";
import { Button } from "../ui/button";
import samplePodcast from "../../assets/audio/unicorns_in_africa.mp3";
import { useAtom } from "jotai";
import {
  currentTimeAtom,
  durationAtom,
  isPlayingAtom,
  audioAtom,
} from "../../Atoms";
import { Slider } from "../ui/slider";
import { getDetailSeries } from "@/services/api/Series";
import mux from 'mux-embed';

const AudioPlayer = () => {
  const audioRef = useRef(null);
  const [currentTime, setCurrentTime] = useAtom(currentTimeAtom);
  const [duration, setDuration] = useAtom(durationAtom);
  const [isPlaying, setIsPlaying] = useAtom(isPlayingAtom);
  const [currentAudio, setCurrentAudio] = useAtom(audioAtom);
  const [currentSeries, setCurrentSeries] = useState(null);

  // Toggle play/pause
  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
    if (!isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  const showDetailSeries = async () => {
    const res = await getDetailSeries(
      currentAudio?.series?.id || currentAudio?.series_id
    );
    setCurrentSeries(res.data);
  };

  useEffect(() => {
    if (audioRef.current) {
      const videoElement = audioRef.current;

      if (videoElement) {
        const initTime = mux.utils.now();
        mux.monitor(videoElement, {
          debug: false,
          data: {
            env_key: process.env.NEXT_PUBLIC_MUX_KEY, // required
            player_name: 'Podcast Episode Audio',
            player_init_time: initTime,
            video_id: currentAudio.id + '-a',
            video_title: 'Audio - ' + currentAudio.title,
            video_series: currentAudio?.series?.title,
            // Additional metadata can be added here
          }
        });
      }
    }
  }, [audioRef, currentAudio]); // Ensure dependencies are correct

  useEffect(() => {
    if (currentAudio?.series?.id || currentAudio?.series_id) {
      showDetailSeries();
    }
  }, [currentAudio]);

  useEffect(() => {
    const audio = audioRef.current;

    // Define event listeners to handle play, pause, and ended events
    const onPlay = () => setIsPlaying(true);
    const onPause = () => setIsPlaying(false);
    const onEnded = () => setIsPlaying(false);

    // Add event listeners to the audio element
    audio.addEventListener("play", onPlay);
    audio.addEventListener("pause", onPause);
    audio.addEventListener("ended", onEnded);

    const updateProgress = () => {
      localStorage.setItem("lcdt-audioPlaybackTime", String(audio.currentTime));
      setCurrentTime(audio.currentTime);
    };

    const setAudioData = () => {
      setDuration(audio.duration);
      // Read the saved playback time from localStorage
      const savedTime = parseFloat(
        localStorage.getItem("lcdt-audioPlaybackTime")
      );
      if (!isNaN(savedTime) && savedTime <= audio.duration) {
        audio.currentTime = savedTime;
        setCurrentTime(savedTime);
      }
      audio.play();
    };

    audio.addEventListener("loadedmetadata", setAudioData);
    audio.addEventListener("timeupdate", updateProgress);

    if (audio.readyState >= 1) {
      setAudioData();
    }

    // Return a cleanup function to remove event listeners
    return () => {
      audio.removeEventListener("loadedmetadata", setAudioData);
      audio.removeEventListener("timeupdate", updateProgress);
      audio.removeEventListener("play", onPlay);
      audio.removeEventListener("pause", onPause);
      audio.removeEventListener("ended", onEnded);
    };
  }, [setDuration, setCurrentTime]);

  // Calculate slider value based on currentTime and duration
  const sliderValue = duration ? (currentTime / duration) * 100 : 0;

  const handleSliderChange = (value) => {
    const newTime = (value / 100) * duration;
    localStorage.setItem("lcdt-audioPlaybackTime", newTime.toString());
    setCurrentTime(newTime);
    audioRef.current.currentTime = newTime;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const onStop = () => {
    setIsPlaying(false);
    setCurrentAudio(null);
    setDuration(0);
    setCurrentSeries(null);
    setCurrentTime(0);
    localStorage.removeItem("lcdt-audioPlaybackTime");
    localStorage.removeItem("lcdt-podcast");
  };

  return (
    <div className='fixed bottom-0 z-50 w-full text-white border-t bg-[#161617]/95 backdrop-blur-lg'>
      <div className='items-center px-4 py-4 mx-auto lg:flex max-w-7xl'>
        <div className='items-center flex-1 lg:flex'>
          <div className='flex items-center flex-1'>
            <button
              onClick={togglePlayPause}
              type='button'
              className='inline-flex items-center justify-center w-12 h-12 text-2xl bg-white rounded-full lg:text-4xl lg:w-16 lg:h-16 text-background'
            >
              {isPlaying ? <Pause weight='fill' /> : <Play weight='fill' />}
            </button>
            <div className='flex-1 ml-3 lg:ml-4'>
              <p className='text-xs font-medium opacity-40 mb-0.5'>
                Episode{" "}
                {currentSeries?.episodes.findIndex(
                  (episode) => episode.uuid === currentAudio?.uuid
                ) + 1}{" "}
                of {currentSeries?.episodes.length}
              </p>
              <p className='text-base font-medium leading-tight line-clamp-1 lg:text-lg'>
                {currentAudio?.title}
              </p>
              <div className='flex items-center w-full mt-2 lg:mt-0.5 space-x-3 lg:space-x-4'>
                <Slider
                  defaultValue={[0]}
                  value={[sliderValue]}
                  onValueChange={(value) => handleSliderChange(value[0])}
                  max={100}
                  step={1}
                />
                <span className='flex-shrink-0 font-mono text-xs lg:text-sm opacity-40'>
                  {formatTime(currentTime)} / {formatTime(duration)}
                </span>
              </div>
            </div>
          </div>

          <div className='flex items-center mt-4 space-x-2 lg:mt-0 lg:border-l lg:pl-7 lg:ml-7 border-white/10'>
            <Sheet>
              <SheetTrigger asChild>
                <Button className='flex-1 flex-shrink-0 text-xs font-medium lg:w-auto lg:text-sm text-white/40 bg-white/5 hover:bg-white/10'>
                  Show All Episodes
                  <CaretUpDown weight='bold' className='ml-1.5 text-base' />
                </Button>
              </SheetTrigger>
              <SheetContent className='w-full overflow-y-auto lg:max-w-[420px]'>
                <p className='inline-block mb-1 text-xs font-medium text-transparent bg-clip-text bg-gradient-to-r from-yellow-1 to-red-1'>
                  {currentSeries?.episodes.length} Episodes
                </p>
                <p className='text-xl font-bold leading-tight tracking-tight'>
                  {currentSeries?.title}
                </p>
                <div className='pt-5 -my-3 divide-y rounded-lg divide-dashed divide-white/20'>
                  {currentSeries?.episodes?.map((episode, index) => (
                    <RelatedPodcast
                      key={index}
                      episode={episode}
                      index={index}
                      audio={true}
                      playingNow={currentAudio?.uuid == episode.uuid}
                      currentTime={currentTime}
                      duration={duration}
                      sliderValue={sliderValue}
                      currentPlaying={currentAudio}
                    />
                  ))}
                </div>
              </SheetContent>
            </Sheet>
            <Button
              onClick={onStop}
              className='flex-shrink-0 text-xs font-medium lg:text-sm text-white/40 bg-white/5 hover:bg-white/10'
            >
              <StopCircle weight='fill' className='mr-1.5 text-xl' />
              Stop and Close
            </Button>
          </div>
        </div>
      </div>
      <audio
        ref={audioRef}
        src={currentAudio?.video_url}
        preload='metadata'
      ></audio>
    </div>
  );
};

export default AudioPlayer;
