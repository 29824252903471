import React, { useEffect, useRef } from 'react';
import OTPInput from 'react-otp-input';
import { Label } from '../ui/label';
import { Button } from '../ui/button';

const InputTokenForAccountRegister = ({ isVisible, onVerifyTokenUserRegister, emailVerificationLoading, confirmToken, setConfirmToken, isLoading, onSendTokenForAccountRegistration, sendEmailVerificationLoading }) => {
  // Ref for the OTPInput component
  const otpRef = useRef(null);

  // Effect to set focus when the modal is opened
  useEffect(() => {
    if (isVisible && otpRef.current) {
      // This assumes that the OTPInput component can be referenced and exposes a way to focus the first input
      const input = otpRef.current.querySelector('input');
      if (input) {
        input.focus();
      }
    }
  }, [isVisible]);

  return (
    <div className='flex flex-col items-center'>
      <Label className='text-xl font-heading'>Verify your Email</Label>
      <p className='mt-1 text-sm opacity-60'>
        Please enter 6 digit verification code sent to your email
      </p>
      <div className='my-4'>
        <OTPInput
          ref={otpRef}
          value={confirmToken}
          onChange={setConfirmToken}
          numInputs={6}
          renderInput={(props) => <input disabled={isLoading} {...props} />}
          inputStyle='border border-background/20 rounded-md text-5xl !px-2 mx-1 font-semibold'
        />
      </div>
      <Button variant='secondary' onClick={onVerifyTokenUserRegister} disabled={emailVerificationLoading}>
        {emailVerificationLoading ? "Processing..." : "Verify"}
      </Button>
      <Button size="sm" onClick={onSendTokenForAccountRegistration} className='border-0 bg-background/5 hover:bg-background/10 text-sm text-slate-600 text-xs mt-4'>
        {sendEmailVerificationLoading
          ? "Processing..."
          : "Resend Code"}
      </Button>
    </div>
  );
};

export default InputTokenForAccountRegister;