import { useContext, useState, useEffect } from "react";
import { useRouter } from "next/router";
import AppContext from "@/AppContext";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CONTENT_TYPES_SLUG } from "@/utils/content";

export default function SearchGlobal() {
  const context = useContext(AppContext);
  const router = useRouter();
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (router.isReady) {
      const { keyword } = router.query;
      if (router.pathname === "/register/content-preferences" && keyword) {
        setSearchQuery(decodeURIComponent(keyword));
      } else if (keyword) {
        setSearchQuery(decodeURIComponent(keyword));
      }
    }
  }, [router.isReady, router.query, router.pathname]);

  const handleSearch = (e) => {
    e.preventDefault();
    let path;
    let queryParam;
    if (selectedFilter === "" || selectedFilter === "all-items") {
      path = "/search/results";
      queryParam = "keyword";
    } else {
      queryParam = "q";
      path = `/${selectedFilter}`;
    }
    router.push(`${path}?${queryParam}=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <form
      onSubmit={handleSearch}
      className='relative max-w-3xl rounded-md shadow-sm'
    >
      <div className='absolute inset-y-0 left-0 flex items-center'>
        <label className='sr-only'>Filter Category</label>
        <Select onValueChange={(value) => setSelectedFilter(value)}>
          <SelectTrigger className='w-36 lg:w-40 !h-auto bg-transparent border-0 border-r !rounded-r-none border-white/5 focus:!outline-none focus:!ring-0 focus:!border-0'>
            <SelectValue placeholder='Select Filter' />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value='all-items'>All Items</SelectItem>
              <SelectItem value='startups'>Startups</SelectItem>
              <SelectItem value='investors'>Venture Capitals</SelectItem>
              {context.state.contentTypes
                .filter((ct) => ct.name !== "Podcast Episodes")
                .map((contentType) => (
                  <SelectItem
                    key={contentType.uuid}
                    value={CONTENT_TYPES_SLUG[contentType.name]}
                  >
                    {contentType.name}
                  </SelectItem>
                ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <input
        type='text'
        placeholder='Search for any content...'
        className='block w-full rounded-lg bg-white/10 border-0 py-2.5 lg:py-1.5 transition-colors pl-40 lg:pl-44 text-white hover:bg-white/15 focus:bg-white/20 placeholder:text-white/30 sm:text-sm sm:leading-6 focus:outline-none'
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </form>
  );
}
