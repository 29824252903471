import { env } from "@/next.config";
import httpUtils from "@/utils/httpUtils";

export async function getInvestorList(config) {
    return httpUtils.get(`${env.API_VERSION}/venture-capital`, config);
}

export async function searchInvestor(config) {
    return httpUtils.get(`${env.API_VERSION}/search-vc`, config);
}

export async function searchInvestorSimpleAll(config) {
    return httpUtils.get(`${env.API_VERSION}/search-vc-simple-all`, config);
}
  
export async function getInvestorDetail(id) {
    return httpUtils.get(`${env.API_VERSION}/venture-capital/${id}`);
}

export async function getInvestorPortfolios(id, config) {
    return httpUtils.get(`${env.API_VERSION}/venture-capital/${id}/startups`, config);
}

export async function getInvestorNews(id, config) {
    return httpUtils.get(`${env.API_VERSION}/venture-capital/${id}/news`, config);
}

export async function getInvestorSpotlights(id, config) {
    return httpUtils.get(`${env.API_VERSION}/venture-capital/${id}/spotlights`, config);
}

export async function getInvestorEpisodes(id, config) {
    return httpUtils.get(`${env.API_VERSION}/venture-capital/${id}/podcast-episodes`, config);
}


export async function getInvestorRelated(id, config) {
    return httpUtils.get(`${env.API_VERSION}/venture-capital/${id}/related`, config);
}

export async function getInvestmentByYear(id, config) {
    return httpUtils.get(`${env.API_VERSION}/number-of-investment/${id}`, config);
}

export async function getInvestmentByRegion(id, config) {
    return httpUtils.get(`${env.API_VERSION}/number-of-investment/${id}/by-region`, config);
}

export async function getInvestmentByFundingType(id, config) {
    return httpUtils.get(`${env.API_VERSION}/sa-breakdown-funding-type/${id}`, config);
}

export async function createSimpleInvestor(payload) {
    return httpUtils.post(`${env.API_VERSION}/venture-capital/simple`, payload);
}

export async function claimVCPage(payload) {
    return httpUtils.post(`${env.API_VERSION}/claim-vc`, payload);
}

export async function getVCPageByVCId(vcId, config) {
    return httpUtils.get(`${env.API_VERSION}/get-claim-vc/${vcId}`, config);
}

export async function updateImageVC(id, payload) {
    return httpUtils.patch(`${env.API_VERSION}/venture-capital/${id}/image`, payload);
}

export async function updateSummaryVC(id, payload) {
    return httpUtils.patch(`${env.API_VERSION}/venture-capital/${id}/summary`, payload);
}

export async function updateBasicInfoVC(id, payload) {
    return httpUtils.patch(`${env.API_VERSION}/venture-capital/${id}/basic-info`, payload);
}

export async function updateStatistic(id, payload) {
    return httpUtils.patch(`${env.API_VERSION}/venture-capital/${id}/statistic`, payload);
}

export async function verifyPortfolios(id, payload) {
    return httpUtils.post(`${env.API_VERSION}/venture-capital/${id}/verify`, payload);
}

export async function unVerifyPortfolios(id, payload) {
    return httpUtils.post(`${env.API_VERSION}/venture-capital/${id}/unverify`, payload);
}

export async function archivePortfolios(id, payload) {
    return httpUtils.post(`${env.API_VERSION}/venture-capital/${id}/archive`, payload);
}


export async function restoreArchivedPortfolios(id, payload) {
    return httpUtils.post(`${env.API_VERSION}/venture-capital/${id}/restore`, payload);
}

export async function getPortfolioData(id) {
    return httpUtils.get(`${env.API_VERSION}/venture-capital/${id}/get-portfolio-data`);
}

export async function approveVCPageClaim(id, config) {
    return httpUtils.get(`${env.API_VERSION}/claim-vc-page/approve/${id}`, config);
}

export async function listAddedPortfolio(config) {
    return httpUtils.get(`${env.API_VERSION}/venture-capital/added-portfolios`, config);
}

export async function createAddedPortfolio(payload) {
    return httpUtils.post(`${env.API_VERSION}/venture-capital/added-portfolios`, payload);
}

export async function updateAddedPortfolio(id, payload) {
    return httpUtils.put(`${env.API_VERSION}/venture-capital/added-portfolios/${id}`, payload);
}

export async function updatePublishVC(id, payload) {
    return httpUtils.patch(`${env.API_VERSION}/venture-capital/${id}`, payload);
}

export async function deleteAddedPortfolio(id) {
    return httpUtils.delete(`${env.API_VERSION}/venture-capital/added-portfolios/${id}`);
}

export async function pitchToVC(payload) {
    return httpUtils.post(`${env.API_VERSION}/pitch-to-vc`, payload);
}

export async function getStartupPitchToVC(startupId) {
    return httpUtils.get(`${env.API_VERSION}/pitch-to-vc/by-startup/${startupId}`);
}

export async function getStartupPitchToVCByVC(vcId) {
    return httpUtils.get(`${env.API_VERSION}/pitch-to-vc/by-vc/${vcId}`);
}

export async function checkCanSubmitPitch(payload) {
    return httpUtils.post(`${env.API_VERSION}/pitch-to-vc/can-submit`, payload);
}

export async function getUserVcs(config) {
    return httpUtils.get(`${env.API_VERSION}/vc-invitations/get-user-vcs`, config);
}

export async function getAllVcAccesses(vcId, config) {
    return httpUtils.get(`${env.API_VERSION}/vc-invitations/${vcId}`, config);
}

export async function getApprovedVcAccesses(vcId, config) {
    return httpUtils.get(`${env.API_VERSION}/vc-invitations/${vcId}/approved`, config);
}

export async function sendVcAccessInvitation(payload) {
    return httpUtils.post(`${env.API_VERSION}/vc-invitations`, payload);
}

export async function acceptInvitation(uuid) {
    return httpUtils.post(`${env.API_VERSION}/vc-invitations/${uuid}/approve`);
}

export async function getAccessByVcId(vcId, config) {
    return httpUtils.get(`${env.API_VERSION}/vc-invitations/${vcId}/get-access`, config);
}

export async function getAllAccessByVcId(vcId, config) {
    return httpUtils.get(`${env.API_VERSION}/vc-invitations/${vcId}/get-all-access`, config);
}

export function trackVCAccessCounting(id, payload) {
    return httpUtils.post(`${env.API_VERSION}/venture-capital/increment-count/${id}`, payload);
}

export async function createVCByUser(payload) {
    return httpUtils.post(`${env.API_VERSION}/venture-capital/create-by-user`, payload);
}

export async function checkVCExist(params) {
    return httpUtils.get(`${env.API_VERSION}/check-vc-exist`, { params });
}

export async function getMyVCList(config) {
    return httpUtils.get(`${env.API_VERSION}/venture-capital/my-list`, config);
}

export async function updateVC(id, payload) {
    return httpUtils.put(`${env.API_VERSION}/venture-capitals/${id}`, payload);
}

export async function removeAccess(id, config) {
    return httpUtils.delete(`${env.API_VERSION}/vc-invitations/remove-access/${id}`, config);
}

export async function removeEditAccess(id, config) {
    return httpUtils.delete(`${env.API_VERSION}/vc-invitations/remove-edit-access/${id}`, config);
}

export async function showVCForUser(config) {
    return httpUtils.get(`${env.API_VERSION}/vc-invitations/show-vc-for-user`, config);
}

export async function addExecutiveTeam(payload) {
    return httpUtils.post(`${env.API_VERSION}/executive-team`, payload);
}

export async function updateExecutiveTeam(id, payload) {
    return httpUtils.put(`${env.API_VERSION}/executive-team/${id}`, payload);
}

export async function deleteExecutiveTeam(id) {
    return httpUtils.delete(`${env.API_VERSION}/executive-team/${id}`);
}

export async function getInvestorContents(id, config) {
    return httpUtils.get(`${env.API_VERSION}/venture-capital/${id}/contents`, config);
}