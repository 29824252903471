import { env } from "@/next.config";
import httpUtils from "@/utils/httpUtils";

export async function getSeries(params) {
  return await httpUtils.get(`${env.API_VERSION}/podcast-series`, { params });
}

export async function getDetailSeries(id, params) {
  return await httpUtils.get(`${env.API_VERSION}/podcast-series/${id}`, { params });
}

export async function getDetailSeriesBySlug(slug, params) {
  return await httpUtils.get(`${env.API_VERSION}/podcast-series/${slug}/by-slug`, { params });
}

export async function getPodcastTimestamps(params) {
  return await httpUtils.get(`${env.API_VERSION}/timestamps`, { params });
}
