const availablePrices = {
  AED: {
    "Startup Registration": "price_1NYgkAGlqmUCDtkjczVkNXml",
    "Content Access": "price_1Ny9RUGlqmUCDtkjWKjswYRk",
    "VC Registration": "price_1PIhPSGlqmUCDtkjcRR6L7HH",
  },
  IDR: {
    "Startup Registration": "price_1NYbbqGlqmUCDtkjLR892I0S",
    "Content Access": "price_1Ny9kiGlqmUCDtkjbEMUMIHq",
    "VC Registration": "price_1PIhPSGlqmUCDtkjs3bvPQNW",
  },
  USD: {
    "Startup Registration": "price_1Nl7zkGlqmUCDtkjwPFUcbSa",
    "Content Access": "price_1Ny9SZGlqmUCDtkjtDAWacNF",
    "VC Registration": "price_1PZVOOGlqmUCDtkjmo63NP6N",
  },
  JOD: {
    "Startup Registration": "price_1Nl7zQGlqmUCDtkjlOPPDVED",
    "Content Access": "price_1Ny9WWGlqmUCDtkjEOh1Ul0c",
    "VC Registration": "price_1PsYj4GlqmUCDtkjbZv0wfnr",
  },
  SAR: {
    "Startup Registration": "price_1Nl7yaGlqmUCDtkjIyczrp2Q",
    "Content Access": "price_1Ny9TqGlqmUCDtkj38V6znqg",
    "VC Registration": "price_1PZVQdGlqmUCDtkjlo1vS0Ff",
  },
  EGP: {
    "Startup Registration": "price_1Nl7ywGlqmUCDtkjF2AWcNob",
    "Content Access": "price_1Ny9UmGlqmUCDtkjurbE9Px7",
    "VC Registration": "price_1PsYhvGlqmUCDtkjObfjymoQ",
  },
};

// UAE
// Monthly:price_1Ny9QrGlqmUCDtkj5ZIKlcZD
// Yearly: price_1Ny9RUGlqmUCDtkjWKjswYRk

// USD: 
// Monthly: price_1Ny9RpGlqmUCDtkjsVUrLe3l
// Yearly: price_1Ny9SZGlqmUCDtkjtDAWacNF

// Jordan:
// Monthly:price_1Ny9W0GlqmUCDtkj4q6Hp5Y0
// Yearly: price_1Ny9WWGlqmUCDtkjEOh1Ul0c

// KSA:
// Monthly: price_1Ny9TUGlqmUCDtkjMVMIRlnH
// Yearly: price_1Ny9TqGlqmUCDtkj38V6znqg

// Egypt:
// Monthly: price_1Ny9UPGlqmUCDtkjFA7bwNcy
// Yearly: price_1Ny9UmGlqmUCDtkjurbE9Px7


export default availablePrices;
