import { PlayCircle } from "@phosphor-icons/react";
import Image from "next/legacy/image";
import React from "react";
import secondsToMinutes from "@/utils/secondsToMinutes";
import { useAtom } from "jotai";
import { audioAtom } from "@/Atoms";
import { useRouter } from "next/router";
import { Slider } from "../ui/slider";

const RelatedPodcast = ({
  episode,
  index,
  audio = false,
  playingNow = false,
  currentTime,
  duration,
  sliderValue,
  playingDetail = false,
}) => {
  const [currentAudio, setCurrentAudio] = useAtom(audioAtom);
  const router = useRouter();

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const onSelectAudio = () => {
    localStorage.setItem("lcdt-podcast", JSON.stringify(episode));
    setCurrentAudio(episode);
  };

  const handlePlay = () => {
    if (episode.is_coming_soon) {
      return;
    }

    if (audio) {
      onSelectAudio();
    } else {
      setCurrentAudio(null);
      localStorage.removeItem("lcdt-audioPlaybackTime");
      localStorage.removeItem("lcdt-podcast");
      router.push(`/podcasts-masterclasses/${episode.slug}`);
    }
  };

  return (
    <button
      type='button'
      onClick={handlePlay}
      className='flex items-start py-2 lg:py-2.5 space-x-3 group'
    >
      <div className='relative h-16 overflow-hidden rounded-lg aspect-video'>
        <Image
          src={episode.thumbnail_url}
          width={1600}
          height={900}
          alt=''
          className='object-cover'
        />
      </div>
      <div className='flex-1 text-left'>
        <p className='mb-1 text-[10px] font-medium opacity-40'>
          Episode {index + 1}
        </p>
        <p className='text-xs font-medium leading-normal'>{episode.title}</p>
        <div className='flex items-center justify-between mt-1.5 space-x-2'>
          {playingNow ? (
            <div className='flex items-center w-full mt-2 lg:mt-0.5 space-x-3 lg:space-x-4'>
              <p className='inline-flex items-center font-medium text-[10px] transition-colors text-white/40'>
                Now Playing
              </p>
              <span className='flex-1 h-px bg-white/20'></span>
              <span className='flex-shrink-0 font-mono text-xs font-medium opacity-40'>
                {formatTime(currentTime)} / {formatTime(duration)}
              </span>
            </div>
          ) : (
            <>
              {playingDetail ? (
                <p className='inline-flex items-center font-medium text-[10px] transition-colors text-white/40'>
                  Now Playing
                </p>
              ) : !!!episode.is_coming_soon ? (
                <>
                  <div className='inline-flex items-center font-medium text-[10px] transition-colors text-white/70 group-hover:text-white'>
                    {audio ? "Listen" : "Watch"} Episode
                    <PlayCircle weight='fill' className='ml-1 text-2xl' />
                  </div>
                  {episode.seconds && (
                    <>
                      <span className='flex-1 h-px bg-white/20'></span>
                      <span className='font-mono text-xs font-medium opacity-40'>
                        {secondsToMinutes(episode.seconds)}
                      </span>
                    </>
                  )}
                </>
              ) : (
                <p className='inline-flex items-center font-medium text-[10px] transition-colors text-white/40'>
                  Coming Soon
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </button>
  );
};

export default RelatedPodcast;
